<template>
  <div>
    <button type="button" @click="open = !open" class="ml-auto block text-right pt-7 pr-0 xs:pr-4 md:hidden">
            <span
                class="inline-block cursor-pointer border-2 border-primary-dark bg-white text-primary-dark p-2 px-3 rounded-lg w-10">
                <font-awesome-icon icon="bars"/>
            </span>
    </button>

    <div>
      <ul class="main-menu xs:mr-4 md:mr-0" :class="[{'block md:flex':open},{'hidden md:flex':!open}]">
        <li><a href="/?#features">Features</a></li>
        <li><a href="/how-osca-works">How It Works</a></li>
        <li><a href="/packages">Packages</a></li>
        <li><a href="/application-list">Application List</a></li>
        <li class="login-btn">
          <button class="py-2 m-0" type="button" @click.prevent="handleShowLoginModal">Login</button>
        </li>
        <li><a href="/sermi" class="rounded bg-lime-600 text-white ml-2">SERMI</a></li>
        <li class="md:px-2">
          <a href="/shop"
             class="block md:inline bg-primary-dark main-menu-btn rounded text-white w-full">Buy
            Now!</a>
        </li>
        <li class="basket md:py-0 py-2">
          <a href="/basket">
            <div>
              <font-awesome-icon icon="shopping-basket" class="basket-icon"/>
              <span class="basket-count">{{ basketCount }}</span>
            </div>
          </a>
        </li>

      </ul>
    </div>

    <LoginModal :open="showLoginModal" @close="handleCloseLoginModal"/>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import Modal from './Modal.vue'
import LoginModal from './LoginModal.vue'
import useEventsBus from '../useEventBus'

const {bus} = useEventsBus()

const props = defineProps({
  shoppingCartQty: {
    type: Number,
    default: 0
  }
})

const open = ref(false)
const basketCount = ref(props.shoppingCartQty)

watch(() => bus.value.get('update-shopping-cart'), (val) => {
      const [updateShoppingCart] = val ?? []
      basketCount.value = updateShoppingCart
    }, {deep: true}
)

const showLoginModal = ref(false)

function handleShowLoginModal() {
  showLoginModal.value = true
}

function handleCloseLoginModal() {
  showLoginModal.value = false
}
</script>